import cn from '../utils/cn'

export default function Intro({ children, small }) {
  return (
    <p
      className={cn(
        'leading-relaxed xs:leading-relaxed lg:leading-relaxed max-w-3xl md:text-center md:mx-auto text-gray-600',
        small ? 'xs:text-lg lg:text-xl' : 'text-lg xs:text-xl lg:text-2xl'
      )}
    >
      {children}
    </p>
  )
}
