import cn from '../utils/cn'

export default function Section({
  className,
  children,
  large,
  small,
  huge,
  lgNoMargin,
}) {
  return (
    <div
      className={cn(
        'section',
        className,
        huge
          ? 'mb-16 xs:mb-20'
          : large
          ? 'mb-12 xs:mb-16'
          : small
          ? 'mb-6 xs:mb-8'
          : 'mb-10 xs:mb-12',
        lgNoMargin
          ? 'lg:mb-0'
          : huge
          ? 'lg:mb-28'
          : large
          ? 'lg:mb-24'
          : small
          ? 'lg:mb-10'
          : 'lg:mb-16'
      )}
    >
      {children}
    </div>
  )
}
