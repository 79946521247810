import cn from '../utils/cn'

export default function Wrap({ children, blue, top, bottom }) {
  return (
    <div
      className={cn(
        'w-full',
        blue &&
          (top
            ? 'pt-16 xs:pt-20 lg:pt-28'
            : bottom
            ? 'pb-16 xs:pb-20 lg:pb-28'
            : 'pt-12 xs:pt-16 lg:pt-24'),
        blue &&
          (top
            ? 'pb-12 xs:pb-16 lg:pb-24'
            : bottom
            ? 'pt-12 xs:pt-16 lg:pt-24'
            : 'pb-12 xs:pb-16 lg:pb-24'),
        blue && 'bg-teal-50'
      )}
    >
      <div
        style={{ maxWidth: '1360px' }}
        className="w-full mx-auto px-5 md:px-10"
      >
        {children}
      </div>
    </div>
  )
}
