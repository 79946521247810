import Wrap from '../components/Wrap'
import Menu from '../components/Menu'
import Section from '../components/Section'
import Info from '../components/Info'
import Form from '../components/Form'
import Intro from '../components/Intro'
import Footer from '../components/Footer'
import Heading from '../components/Heading'

export default function Contact() {
  return (
    <>
      <Section large>
        <Menu />
      </Section>
      <Section>
        <Wrap>
          <Heading type={1} size={1} center>
            Contact
          </Heading>
          <Intro>
            Heb je een vraag of ben je geïnteresseerd in een abonnement? We
            reageren altijd binnen 48 uur.
          </Intro>
        </Wrap>
      </Section>
      <Wrap blue bottom>
        <div className="flex flex-col lg:flex-row items-start">
          <Info />
          <Form />
        </div>
      </Wrap>
      <Footer noMargin />
    </>
  )
}
