import { Link } from 'gatsby'
import cn from '../utils/cn'

export default function Button({ to, small, children, submit, blank }) {
  const classes = cn(
    'bg-edi-500 hover:bg-edi-600 transition-colors duration-300 text-center flex items-center text-white tracking-wide uppercase font-bold',
    small
      ? 'h-8 xs:h-10 text-xs xs:text-sm px-6 rounded-md shadow'
      : 'h-12 xs:h-14 text-sm xs:text-base px-8 rounded-lg shadow-md'
  )

  return (
    <div className="flex justify-center">
      {submit ? (
        <button type="submit" className={cn('mt-6 xs:mt-8', classes)}>
          {children}
        </button>
      ) : blank ? (
        <a href={to} className={classes} target="_blank" rel="noopener">
          {children}
        </a>
      ) : (
        <Link to={to} className={classes}>
          {children}
        </Link>
      )}
    </div>
  )
}
