import cn from '../utils/cn'
import Wrap from './Wrap'

export default function Footer({ noMargin }) {
  return (
    <div
      className={cn(
        'bg-gray-800 py-12 xs:py-16 text-white flex items-center text-center',
        !noMargin && 'mt-16 xs:mt-20 lg:mt-28'
      )}
    >
      <Wrap>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="xs:text-lg font-semibold mb-8 md:mb-0">
            &copy; {new Date().getFullYear()} EDI-Central B.V.
          </p>
          <div className="space-y-4 md:space-y-0 md:space-x-8 flex flex-col md:flex-row">
            <a
              className="underline text-sm xs:text-base hover:text-blue-400"
              href="/2020-11-Algemene-voorwaarden-EDI-Central-BV.pdf"
              target="_blank"
              rel="noopener"
            >
              Algemene voorwaarden
            </a>
            <a
              className="underline text-sm xs:text-base hover:text-blue-400"
              href="/2020-11-Privacyverklaring.pdf"
              target="_blank"
              rel="noopener"
            >
              Privacy statement
            </a>
          </div>
        </div>
      </Wrap>
    </div>
  )
}
