import { Link } from 'gatsby'

export default function Logo() {
  return (
    <Link to="/" className="flex items-center font-semibold text-lg xs:text-xl">
      <svg
        className="w-8 xs:w-10 h-8 xs:h-10 mr-3 flex-none text-edi-500"
        viewBox="0 0 26.43 26.05"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="m18.148241 13.038241c.0026944.3747753-.1449908.7349831-.41 1l-3.72 3.72c-.2646648.2662537-.6245822.4159678-1 .4159678s-.7353352-.1497141-1-.4159678l-2.8800001-2.92c-.36795834-.3681096-.90436847-.511919-1.40712146-.3772437-.50275299.1346754-.89544687.5273693-1.03012222 1.0301222-.13467534.502753.00913408 1.0391632.37724368 1.4071215l5.2100001 5.21c.1890717.2019503.4533557.3165529.73.3165529s.5409283-.1146026.73-.3165529l6.15-6.15c.5191477-.5163862 1.3586137-.5141476 1.875.005.5163862.5191477.5141476 1.3586138-.005 1.875l-7.82 7.82c-.2476785.24854-.5841201.3882409-.935.3882409s-.6873215-.1397009-.935-.3882409l-11.69000009-11.69c-.24853996-.2476785-.38824091-.5841201-.38824091-.935s.13970095-.6873215.38824091-.935l11.71000009-11.71000009c.2476785-.24853996.5841201-.38824091.935-.38824091s.6873215.13970095.935.38824091l7.82 7.81999999c.5163862.51638625.5163862 1.35361376 0 1.87-.5163862.5163863-1.3536137.5163863-1.87.0000001l-6.15-6.1500001c-.1890717-.20195032-.4533557-.31655286-.73-.31655286s-.5409283.11460254-.73.31655286l-5.2100001 5.21c-.56885332.56885329-.56885334 1.4911467-.00000005 2.0600001.56885329.5688533 1.49114673.5688533 2.06000005 0l2.8900001-2.8800001c.2646648-.26625372.6245822-.41596781 1-.41596781s.7353352.14971409 1 .41596781l3.72 3.7200001c.2650092.2650169.4126944.6252247.41 1m6.9 1.55 1.22-1.22c.1792826-.1795794.1792826-.4704206 0-.65l-1.22-1.22c-.1795794-.1792826-.4704206-.1792826-.65 0l-1.22 1.22c-.1792826.1795794-.1792826.4704206 0 .65l1.18 1.22c.0866218.0891982.2056631.1395316.33.1395316s.2433782-.0503334.33-.1395316z"
        />
      </svg>
      <div>
        <span className="text-edi-500">EDI</span>-Central
      </div>
    </Link>
  )
}
