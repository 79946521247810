import Wrap from './Wrap'
import cn from '../utils/cn'
import { Turn as Hamburger } from 'hamburger-react'
import Button from './Button'
import Logo from './Logo'
import { Link } from 'gatsby'
import { useState } from 'react'

export default function Menu() {
  const [toggled, toggle] = useState(false)

  return (
    <div className="py-2 md:py-4 shadow">
      <Wrap>
        <div className="flex items-center justify-between">
          <Logo />
          <div
            className={cn(
              'flex flex-col transform items-center transition duration-300 z-10 bg-white py-16 absolute top-0 right-0 space-y-2 shadow-md rounded-bl-xl',
              !toggled && '-translate-y-8 opacity-0 pointer-events-none',
              'md:flex-row md:pointer-events-auto md:py-0 md:static md:space-y-0 md:space-x-8 lg:space-x-12 md:shadow-none md:translate-y-0 md:opacity-100'
            )}
          >
            <MenuLink to="/abonnementen">Abonnementen</MenuLink>
            <MenuLink to="/contact">Contact</MenuLink>
            <div className="pt-2 md:pt-0">
              <Button small to="https://portal.edi-central.nl" blank>
                Portaal login
              </Button>
            </div>
          </div>
          <div className="md:hidden z-10">
            <Hamburger
              easing="cubic-bezier(0.4, 0, 0.2, 1)"
              duration={0.3}
              label="menu"
              size={28}
              toggle={toggle}
              toggled={toggled}
            />
          </div>
        </div>
      </Wrap>
    </div>
  )
}

const MenuLink = ({ to, children }) => (
  <Link
    className="font-bold hover:underline px-16 md:px-0 py-2 text-center"
    to={to}
  >
    {children}
  </Link>
)
