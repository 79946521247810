import Button from './Button'
import Heading from './Heading'
import cn from '../utils/cn'
import { useState } from 'react'

const classes = {
  form:
    'bg-white shadow-xl rounded-xl p-6 xs:p-8 lg:p-12 flex flex-wrap justify-between items-start w-full lg:w-1/2 xl:w-3/5 flex-none',
  label: 'font-bold xs:text-lg mb-1.5 select-none block',
  input:
    'border-gray-300 border shadow-inner rounded-md px-3.5 py-2 appearance-none w-full block',
}

export default function Form() {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  return success ? (
    <div className={classes.form}>
      <Success />
    </div>
  ) : (
    <form
      onSubmit={async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
          const response = await fetch(
            'https://configuration.edi-central.nl/verzend/contactformulier',
            {
              body: new URLSearchParams(new FormData(e.target)).toString(),
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              method: 'POST',
            }
          )

          if (!response.ok) {
            throw Error(response.statusText)
          }

          setSuccess(true)
          setLoading(false)
        } catch (error) {
          setError(error)
          setLoading(false)
        }
      }}
      className={classes.form}
    >
      <Input required type="text" id="FirstName" label="Voornaam *" />
      <Input required type="text" id="LastName" label="Achternaam *" />
      <Input required type="text" id="Company" label="Bedrijfsnaam *" />
      <Input required type="text" id="PhoneNumber" label="Telefoon *" />
      <Input required type="email" id="EMail" label="E-mail *" />
      <fieldset className="w-full md:w-3/4 lg:w-full xl:w-3/4 mb-4 xs:mb-6">
        <label className={classes.label} htmlFor="Comment">
          Opmerkingen/vragen
        </label>
        <textarea
          className={classes.input}
          rows="6"
          id="Comment"
          name="Comment"
        ></textarea>
      </fieldset>
      <Checkbox
        required
        id="terms"
        label={
          <>
            Ik ga akkoord met de{' '}
            <Anchor to="/2020-11-Algemene-voorwaarden-EDI-Central-BV.pdf">
              algemene voorwaarden
            </Anchor>{' '}
            en{' '}
            <Anchor to="/2020-11-Privacyverklaring.pdf">
              privacy statement
            </Anchor>{' '}
            *
          </>
        }
      />
      <Checkbox
        noMargin
        id="SubscribeToFutureNews"
        label="Ik wens in de toekomst nieuwsberichten van EDI-Central te ontvangen"
      />
      <Button submit>{loading ? 'Bezig…' : 'Verzenden'}</Button>
      {error && <Error />}
    </form>
  )
}

const Success = () => (
  <div className="w-full">
    <Heading type={2} size={3}>
      Formulier verzonden
    </Heading>
    <p className="border-green-300 border text-green-900 bg-green-50 p-4 xs:p-6 rounded-md">
      Bedankt voor uw aanvraag! We nemen zo snel mogelijk contact op.
    </p>
  </div>
)

const Error = () => (
  <p className="w-full mt-4 xs:mt-6 border-red-300 border text-red-900 bg-red-50 p-4 xs:p-6 rounded-md">
    Excuses, het is niet gelukt om uw aanvraag te versturen. Probeer het op een
    later moment nogmaals.
  </p>
)

const Input = ({ required, type, id, label }) => (
  <fieldset className="w-full xs:w-3/4 md:w-3/5 lg:w-full xl:w-1/2-4 mb-3 xs:mb-4">
    <label className={classes.label} htmlFor={id}>
      {label}
    </label>
    <input
      className={classes.input}
      required={required}
      type={type}
      id={id}
      name={id}
    />
  </fieldset>
)

const Checkbox = ({ required, id, label, noMargin }) => (
  <label
    htmlFor={id}
    className={cn(
      'flex w-full text-sm leading-tight items-start select-none',
      !noMargin && 'mb-3 xs:mb-4'
    )}
  >
    <input
      className="mr-2 mt-0.5 flex-none"
      required={required}
      type="checkbox"
      id={id}
      name={id}
      value="true"
    />
    <span className="text-gray-800">{label}</span>
  </label>
)

const Anchor = ({ to, children }) => (
  <a
    target="_blank"
    rel="noopener"
    className="text-blue-700 underline hover:text-blue-800"
    href={to}
  >
    {children}
  </a>
)
