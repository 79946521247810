import cn from '../utils/cn'

export default function Heading({
  auto,
  children,
  reference,
  type,
  size,
  className,
  narrow,
  center,
  white,
  margin = true,
}) {
  const classes = cn(
    'font-semibold leading-tight xs:leading-tight lg:leading-tight',
    className,
    center && 'md:text-center',
    (auto || center) && 'md:mx-auto',
    narrow ? 'max-w-2xl' : 'max-w-3xl',
    margin && 'mb-4 lg:mb-6',
    white ? 'text-white' : 'text-gray-800',
    size == 1
      ? 'text-4xl xs:text-5xl lg:text-6xl'
      : size == 3
      ? 'text-2xl xs:text-3xl lg:text-4xl'
      : size == 4
      ? 'text-xl xs:text-2xl lg:text-3xl'
      : size == 5
      ? 'text-base xs:text-lg lg:text-xl'
      : 'text-3xl xs:text-4xl lg:text-5xl'
  )

  return type == 1 ? (
    <h1 className={classes} ref={reference}>
      {children}
    </h1>
  ) : type == 3 ? (
    <h3 className={classes} ref={reference}>
      {children}
    </h3>
  ) : (
    <h2 className={classes} ref={reference}>
      {children}
    </h2>
  )
}
